:root {
    --bg-color: #23272A;
    --bg-secondary-color: #484B4F;
    --bg-dark-color: #34373B;
    --text-color: rgb(255, 255, 255);
    --accent-color: rgb(117, 235, 187);
    --dc-color-blue: #5865F2;
    --dc-color-green: #57F287;
    --dc-color-yellow: #FEE75C;
    --dc-color-pink: #EB459E;
    --dc-color-red: #ED4245;
    --dc-color-dark: #262930;
}

body {
    color: var(--text-color);
    background-color: var(--bg-color);
}

.main {
    position: relative;
}

.main .form-container {
    align-items: center;
    justify-content: center;
    padding: 50px;
    overflow: hidden;
    border-radius: 20px;
    text-align: center;
    margin: 0;
}

.main form input {
    padding: 10px;
    background-color: var(--bg-dark-color);
    border: none;
    margin: 10px 0;
    border-radius: 10px;
    color: var(--text-color);
    width: 350px;
}

@media only screen and (max-width: 600px) {
    .main form input {
        width: 200px;
    }
}

.main form input[type=submit] {
    color: black;
    background-color: var(--accent-color);
}

.main .warning {
    background-color: var(--dc-color-red);
    border-radius: 10px;
    margin: 10px 0;
    color: white;
    padding: 10px;
    text-align: center;
}

.main .warning.yellow {
    background-color: var(--dc-color-yellow);
    color: black;
}

.split {
    display: flex;
    justify-content: center;
}

.split .line {
    width: 80%;
    height: 1px;
    background-color: var(--accent-color);
    margin: 20px 0;
}

.main h2 {
    color: var(--text-color);
    margin: 0;
    padding: 0;
    text-align: center;
    margin-top: 10px;
}

.last-queries {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 50px;
}

.last-queries .query-element {
    background-color: var(--bg-secondary-color);
    border-radius: 10px;
    padding: 10px;
    margin: 10px 0;
    color: var(--text-color);
    max-width: 80%;
}

.last-queries .query-element img {
    border-radius: 10%;
    max-width: 90%;
}

.last-queries .query-element p {
    margin: 0;
    font-family: 'Courier New', Courier, monospace;
}

.last-queries .query-element .video-title {
    font-size: 2.0em;
}

.last-queries .query-element .video-author {
    font-size: 1.2em;
}

.last-queries .query-element .load-video-btn {
    background-color: var(--accent-color);
    color: black;
    border: none;
    border-radius: 10px;
    padding: 10px;
    margin: 10px 0;
    cursor: pointer;
}

.button {
    background-color: var(--accent-color);
    color: black;
    border: none;
    padding: 10px;
    cursor: pointer;
    margin:0 auto;
    display:block;
    width: 200px;
    border: #5865F2 3px solid;
}

.button:hover {
    background-color: var(--dc-color-blue);
    color: white;
}

.button.active {
    background-color: var(--dc-color-blue);
    color: white;
}