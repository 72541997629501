:root {
    --bg-color: #23272A;
    --bg-secondary-color: #484B4F;
    --bg-dark-color: #34373B;
    --text-color: rgb(255, 255, 255);
    --accent-color: rgb(117, 235, 187);
    --dc-color-blue: #5865F2;
    --dc-color-green: #57F287;
    --dc-color-yellow: #FEE75C;
    --dc-color-pink: #EB459E;
    --dc-color-red: #ED4245;
    --dc-color-dark: #262930;
}

.warning {
    background-color: var(--dc-color-red);
    border-radius: 10px;
    margin: 10px 0;
    color: white;
    padding: 10px;
    text-align: center;
}