:root {
    --bg-color: #23272A;
    --bg-secondary-color: #484B4F;
    --bg-dark-color: #34373B;
    --text-color: rgb(255, 255, 255);
    --accent-color: rgb(117, 235, 187);
    --dc-color-blue: #5865F2;
    --dc-color-green: #57F287;
    --dc-color-yellow: #FEE75C;
    --dc-color-pink: #EB459E;
    --dc-color-red: #ED4245;
    --dc-color-dark: #262930;
}

body {
    color: var(--text-color);
    background-color: var(--bg-color);
}

.main {
    position: relative;
}

.main form input {
    padding: 10px;
    background-color: var(--bg-dark-color);
    border: none;
    margin: 10px 0;
    border-radius: 10px;
    color: var(--text-color);
    width: 350px;
}

.main form input[type=submit] {
    color: black;
    background-color: var(--accent-color);
}

@media only screen and (max-width: 600px) {
    .main form input {
        width: 200px;
    }
}

.main .warning {
    background-color: var(--dc-color-red);
    border-radius: 10px;
    margin: 10px 0;
    color: white;
    padding: 10px;
}

.main .warning.yellow {
    background-color: var(--dc-color-yellow);
    color: black;
}
